import axios from "axios";
// import swal from "sweetalert/dist/sweetalert.min.js";
// let serverurl = "http://beta.pacificweb.com.ph/";
let serverurl = "https://workflowplus.gzonetechph.com/";
// let serverurl ="http://192.168.1.44/api_pacific/";
let key = "?key=PocketHR@20190208&type=web";


export function loginUser(method, param) {
    return new Promise((resolve, reject) => {
        axios
            .post(serverurl + method + key, {
                param
            })
            .then(res => {
                resolve(res.data);
            });
    });
}
