import React, { Component } from 'react';

import {loginUser} from "./api";



class Login extends Component {
    constructor(props){
        super(props);
        this.state ={
            logindata : [],
            username: "",
            password: "",
            app_id : this.getUrlVars()["app"]
        }
    }

    // GET URL PARAMS

    getUrlVars = () => {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }
  
    getUrlParam = (parameter, defaultvalue) =>{
      var urlparameter = defaultvalue;
      if(window.location.href.indexOf(parameter) > -1){
          urlparameter = this.getUrlVars()[parameter];
          }
      return urlparameter;
    }

    // !GET URL PARAM
    
    login = (e) =>{
        e.preventDefault();

        const userdata = {
            username : this.state.username,
            password : this.state.password,
            app_id : 7
        }
		loginUser("wauth/Loginv2" ,userdata).then(result => {
            this.setState({logindata: result.account_id});
            localStorage.setItem("u", this.state.logindata);
            if(result.res === "Success"){
                window.location.replace(this.getUrlVars()["goto"] + "?u=" + result.account_id)
            }else{
                alert("Invalid");
            }
        })
    }

    inputChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() { 
        return ( 
            <div className="form-holder">
                <div className="form-content">
                    <div className="form-items">
                        <div className="website-logo-inside">
                            <a href="index-2.html">
                                <div className="logo">
                                    <img className="logo-size" src="images/logo-light.svg" alt=""/>
                                </div>
                            </a>
                        </div>
                        <h3>Login</h3>
                        <p>to continue using our platform</p>
                        <div className="page-links">
                            <a href="login14.html" className="active">Login</a><a href="register14.html">Register</a>
                        </div>
                        <form>
                            <input className="form-control" onChange={this.inputChange} type="text" name="username" placeholder="Username" required/>
                            <input className="form-control" onChange={this.inputChange} type="password" name="password" placeholder="Password" required/>
                            <div className="form-button">
                                <button id="submit" type="submit" onClick={this.login} className="ibtn">Login</button> <a href="forget14.html">Forget password?</a>
                            </div>
                        </form>
                        <div className="other-links">
                            {/* <span>Or login with</span><a href="#"><i className="fab fa-facebook-f"></i></a><a href="#"><i className="fab fa-google"></i></a><a href="#"><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Login;